import React, { useState, useEffect } from "react";
import Axios from "axios";
import AsyncSelect from 'react-select/async';
import GoogleTagManager from "../../component/GoogleTagManager";
import PageLayout from "../../component/PageLayout";
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

const options = [
  { value: '', label: 'Please select Confirmation by*' },
  { value: 'Jaya', label: 'Jaya' },
  { value: 'Krina', label: 'Krina' },
  { value: 'Jyoti', label: 'Jyoti' },
  { value: 'Vaibhavi', label: 'Vaibhavi' },
  { value: 'Guddi', label: 'Guddi' },
  { value: 'Vrusha', label: 'Vrusha' },
  { value: 'Siddhi', label: 'Siddhi' },
  { value: 'Ajay', label: 'Ajay' },
  { value: 'Jay', label: 'Jay' },
  { value: 'Hitesh', label: 'Hitesh' },
  { value: 'Dhruv', label: 'Dhruv' },
  { value: 'Siddharth', label: 'Siddharth' },
  { value: 'Aniket', label: 'Aniket' },
  { value: 'Shrikant', label: 'Shrikant' },
  { value: 'Hemang', label: 'Hemang' },
  { value: 'Dhruvil', label: 'Dhruvil' },
  { value: 'Rajesh', label: 'Rajesh' },
  { value: 'Parth', label: 'Parth' }
];

const filterOptions = (inputValue) => {
  return options.filter(option =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterOptions(inputValue));
  }, 1000);
};

export default function EventDetails() {
  const [eventDetails, setEventDetails] = useState({
    title: '',
    date: '',
    startTime: '',
    endTime: '',
    meetingLink: ''
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    tallySerialNo: '',
    organization: '',
    confirmationBy: options[0].value
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [alldate, setAlldate] = useState([]);
  const [activeEventIndex, setActiveEventIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const eventDetailsas = location.state || {};
  const [modeOfMeeting, setModeOfMeeting] = useState('');

  console.log("modeOfMeetingmodeOfMeeting", modeOfMeeting);

const formatdteasTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  let formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return `${formattedHours}:${minutes} ${ampm}`;
};


  const changehandal = (index) => {
    setActiveEventIndex(index);
    const eventDate = alldate[index]?.EventDate;     
    if (eventDate) {
      setEventDetails({
        title: '',
        date: '',
        startTime: '',
        endTime: '',
        meetingLink: ''
      });
      fetchEventssend(eventDate);
    }
  };
  const currentDate = new Date();
  const fetchEvents = async (eventDate) => {
  
    try {
      const response = await Axios.post("https://gshost.in/GS/EventAPI/event.php", {
        GSEvent: {
          event_date: eventDate
        },
      });
      console.log("response response response", response.data.GSEventRes[0].ModeOfMeeting);
      setModeOfMeeting(response.data.GSEventRes[0].ModeOfMeeting);
      

      const futureupcomingEvents =response.data.GSEventRes.filter(event => {
        const eventStartDate = `${event.EventDate}T${event.StartTime}`
        const eventStart = new Date(eventStartDate);
        return eventStart > currentDate;
      });
      futureupcomingEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
      //console.log("responsedata response", futureupcomingEvents);
      if(eventDetailsas.eventDetails === null){
        const filterPattern = "Showcasing Enhanced Capabilities of TallyPrime"; // The substring you want to match

        const eventData = futureupcomingEvents.filter((event) => 
          event.Title.includes(filterPattern)
        );
        console.log("eventDataeventDataeventData", eventData);
        
        const detailsvas = eventData[0]; 
        setEventDetails({
          title: detailsvas.Title,
          date: detailsvas.EventDate,
          startTime:detailsvas.StartTime,
          endTime:detailsvas.EndTime,
          meetingLink: detailsvas.Link
        });
        setAlldate(eventData);
        const activeIndex = eventData.findIndex(event =>{
          const eventStartDate = `${event.EventDate}T${event.StartTime}`
         return new Date(eventStartDate) >= currentDate
     });
     console.log("activeIndex", activeIndex);
     
       setActiveEventIndex(activeIndex);
      }else{
        const filterPattern = "Showcasing Enhanced Capabilities of TallyPrime"; 

        const eventData = futureupcomingEvents.filter((event) => 
          !event.Title.includes(filterPattern) && event.EventDate === eventDate
        );
        const detailsvas = eventData[0]; 
        console.log("dtaisl",eventData);

        //const eventData = futureupcomingEvents.filter((event) => event.Title !== "% Tally Prime Release 5.0  %"); 
        setEventDetails({
          title: detailsvas.Title,
          date: detailsvas.EventDate,
          startTime:detailsvas.StartTime,
          endTime:detailsvas.EndTime,
          meetingLink: detailsvas.Link
        });
        setAlldate(eventData);
        setActiveEventIndex(0);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchEventssend = async (eventDate) => {
    //console.log("dateNew", eventDate);
    
    try {
      const response = await Axios.post("https://gshost.in/GS/EventAPI/event.php", {
        GSEvent: {
          event_date: eventDate
        },
      });
      const futureupcomingEvents =response.data.GSEventRes.filter(event => {
        const eventStartDate = `${event.EventDate}T${event.StartTime}`
        const eventStart = new Date(eventStartDate);
        return eventStart > currentDate;
      });
      futureupcomingEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
      console.log("responsNewSSSDF", futureupcomingEvents);
      

      const eventData = futureupcomingEvents.filter(event => event.EventDate === eventDate); 
      console.log("eventData", eventData[0]);
      const datsra = eventData[0];
      setEventDetails({
        title: datsra.Title,
        date: datsra.EventDate,
        startTime: datsra.StartTime,
        endTime:datsra.EndTime,
        meetingLink: datsra.Link
      });
      console.log("setEventDetails", eventDetails);
      
      //setAlldate(futureupcomingEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {    
    if (eventDetailsas.eventDetails === null) {
      if(activeEventIndex === null){
        const currentDate =eventDetails.date;
        fetchEvents(currentDate);
        }
    } else {
      if(activeEventIndex === null){
        const currentDate =eventDetailsas.eventDetails.eventStartDate;
        fetchEvents(currentDate);
        }
    }
  }, [activeEventIndex, eventDetails.date]);

  // useEffect(() => {
  //   if (activeEventIndex === null) {
  //     // Check if eventDetailsas is undefined or has eventDetails
  //     const currentDate = eventDetailsas?.eventDetails?.eventStartDate || eventDetails.date;
  //     console.log("datrs",currentDate);
      
  //     fetchEvents(currentDate);
  //   }
  // }, [activeEventIndex, eventDetails.date]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value); // Real-time validation
  };
  const handleNumericInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    setFormData({ ...formData, [name]: numericValue });
  };
  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, confirmationBy: selectedOption.value });
  };

  const validateASerialNumber = (serialNo) => {
    let total = 0;
    let totalToVerify = 0;
    let message;
    const serialNoList = Array.from(serialNo).map((digit) => parseInt(digit));

    if (serialNoList.length < 9 || serialNoList[0] !== 7) {
      message = "Tally Serial Number must start with 7 and be 9 digits long";
      setErrors(true);
    } else {
      serialNoList.forEach((digit) => {
        total += digit;
      });

      // Split string total
      const totalSeparate = Array.from(total.toString()).map((digit) =>
        parseInt(digit)
      );

      totalSeparate.forEach((digit) => {
        totalToVerify += digit;
      });

      if (totalToVerify !== serialNoList.length) {
        message = "Invalid Tally Serial No";
        setErrors(true);
      } else {
        message = "";
        setErrors(false);
      }
    }
    return message;
  };


  const validateField = (name, value) => {
    let errorMsg = '';
    switch (name) {
      case 'name':
        if (!value) {
          errorMsg = 'Name is required';
        }
        break;
      case 'email':
        if (!value) {
          errorMsg = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMsg = 'Email address is invalid';
        }
        break;
      case 'mobile':
        if (!value) {
          errorMsg = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(value)) {
          errorMsg = 'Mobile number must be 10 digits';
        }
        break;
      case 'tallySerialNo':
        errorMsg = validateASerialNumber(value);
        // if (value && !/^7\d{8}$/.test(value)) {
        //   errorMsg = 'Tally Serial Number must start with 7 and be 9 digits long';
        // }
        break;
      case 'organization':
        if (!value) {
          errorMsg = 'Organization is required';
        }
        break;
      case 'confirmationBy':
        if (!value) {
          errorMsg = 'Confirmation by is required';
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: errorMsg });
  };
    
  const targetUrl = 'https://gshost.in/GS/EventAPI/event_registration.php';

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields on submit
    let formIsValid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (errors[key]) {
        formIsValid = false;
      }
    });

    if (!formIsValid) {
      return;
    }

    setLoading(true);
    const registrationData = {
      EventReg: [
        {
          TallySerialNo: formData.tallySerialNo,
          Name: formData.name,
          EmailId: formData.email,
          MobileNo: formData.mobile,
          Organization: formData.organization,
          EventDate: eventDetails.date,
          RegistrationDate: new Date().toISOString().split('T')[0],
          ConfirmationBy: formData.confirmationBy,
          Title: eventDetails.title,
          StartTime: eventDetails.startTime,
          EndTime: eventDetails.endTime,
          MeetingLink: eventDetails.meetingLink
        }
      ]
    };

    try {
      const response = await Axios.post(
        'https://gshost.in/GS/EventAPI/event_registration.php',
        registrationData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
    
      setSubmissionStatus("Thank you for your submission!");
      setTimeout(() => {
        setLoading(false);
        navigate("/events");
      }, 2000);
    } catch (error) {
      console.error("Error submitting registration:", error);
      setSubmissionStatus("There was an error submitting your form. Please try again.");
      setLoading(false);
    }
  };



  return (
    <div className="home-main eventpage eventRegisterpage">
      <GoogleTagManager />
      <PageLayout>
        <div className="text-center">
          <div className="Innerpage-title">
            <h1 className="title1 text-center box-default">Events Details</h1>
          </div>
        </div>
        <section className="pt50 calendar-main">
          <div className="container">
            <div className="event-info">
              <div className="formtitle text-center col-md-12 col-12">
              <h3><strong>{eventDetails.title}</strong></h3>

              <div className="datetimemain">
                <div className="eventbuttons d-flex flex-wrap gap-3 my-3">
                {alldate.map((eventdate, index) => (
                  
                    <button
                      key={index}
                      type="submit"
                     className={`btn btn-danger ${index === activeEventIndex ? 'btn-dark' : ''}`}
                     onClick={() => changehandal(index)}
                    >
                      <label>                       
                        {/* {eventdate.EventDate} */}
                        {new Date(eventdate.EventDate).toLocaleDateString('en-GB')}
                        <small>( {formatdteasTime(eventdate.StartTime)} - {formatdteasTime(eventdate.EndTime)} )</small>
                      </label>
                      <span></span>
                    </button>                                        
                  ))}             
               </div>
               </div>
                {/* <span>( Date : {eventDetails.date} | Time : {eventDetails.startTime} to {eventDetails.endTime} )</span> */}
              </div>
              
              {submissionStatus && (
                  <div className="submission-status">
                    <h5>{submissionStatus}</h5>
                  </div>
                )}

              <form className="form-main d-flex flex-wrap" onSubmit={handleSubmit}>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name*"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                    required
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Id*"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number*"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleNumericInputChange}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                    maxLength={10}
                    required
                  />
                  {errors.mobile && <p className="error">{errors.mobile}</p>}
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Tally Serial Number (i.e.: 7********)"
                    name="tallySerialNo"
                    value={formData.tallySerialNo}
                    onChange={handleNumericInputChange}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                    maxLength={9}
                  />
                  {/* <p>Enter Serial Number starting from 7</p> */}
                  {errors.tallySerialNo && <p className="error">{errors.tallySerialNo}</p>}
                </div>
                <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Organization Name*"
                    name="organization"
                    value={formData.organization}
                    onChange={handleInputChange}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                    required
                  />
                  {errors.organization && <p className="error">{errors.organization}</p>}
                </div>
                {modeOfMeeting !== "Offline Meeting" && (
                  <div className="col-lg-4 col-12 col-sm-6 col-md-6">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions={options}
                      defaultValue={options[0]}
                      className="form-control"
                      name="confirmation"
                      onChange={handleSelectChange}
                    />
                  </div>
                )}
                 {/* {errors.confirmationBy && <p className="error">{errors.confirmationBy}</p>} */}
              
                <div className="col-md-12 col-12 text-center">
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={loading} 
                >
                  {loading ? (                    
                    <>
                    <label>
                    Loading...
                    </label>
                    <span></span>
                  </>
                  ) : (
                    <>
                      <label>
                        Send <IconRightArrow />
                      </label>
                      <span></span>
                    </>
                  )}
                </button>
                </div>
              
              </form>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
