import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { BrowserRouter} from 'react-router-dom';
import '../src/assest/css/fonts.css';
import '../src/assest/css/style.css';
import LoadingAnimation from '../src/component/SvgIcons/LoadingAnimation.json';
import './App.css';
import { SmoothScrollContainer } from './SmoothScrollContainer';
import NavigationRouting from './component/NavigationRouting';
import IconUp from './component/SvgIcons/IconUp';

function App() {
  const [loading, setLoading] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));      
      $('.loader-container').fadeOut(500, () => setLoading(false));
    };

    fetchData();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  
const ballRef = useRef(null);
const contentRef = useRef(null);

useEffect(() => {
  const ball = ballRef.current;
  let mouseX = 0;
  let mouseY = 0;
  let ballX = 0;
  let ballY = 0;
  const speed = 0.1;

  function animate(event) {
    const contentRect = contentRef.current.getBoundingClientRect();
    const contentHeight = contentRect.height;
    const innerWindowWidth = window.innerWidth;

    let distX = mouseX - ballX;
    let distY = mouseY - ballY;
    ballX = Math.max(-400, Math.min(innerWindowWidth - 200, ballX + (distX * speed))); // Ensure ball stays within the inner window width
    ballY = Math.max(-400, Math.min(contentHeight - 200, ballY + (distY * speed)));
    ball.style.left = ballX + "px";
    ball.style.top = ballY + "px";
  }

  function handleMouseMove(event) {
    mouseX = event.pageX;
    mouseY = event.pageY;
    animate();
  }

  document.addEventListener("mousemove", handleMouseMove);
  return () => {
    document.removeEventListener("mousemove", handleMouseMove);
  };
}, []);

useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => window.removeEventListener('scroll', handleScroll);
}, []);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

return (
    <div className='appload' ref={contentRef}>
    <SmoothScrollContainer>
      <BrowserRouter>
        <NavigationRouting />
        {loading && (
          <div className="loader-container">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        )}        
        <div ref={ballRef} className="ball"></div>
      </BrowserRouter>
      </SmoothScrollContainer>

      {showBackToTop && (
        <button onClick={scrollToTop} className='backtotop'>
         <IconUp/>
        </button>
      )}
      </div>
  );
}

export default App;
