import React from 'react'
import HomeBanner from '../../component/HomeBanner'
import OurClient from '../../component/OurClient'
import PageLayout from '../../component/PageLayout'
import TallyAppsTabs from '../../component/TallyAppsTabs'
import TallyProducts from '../../component/TallyProducts'
import WhatWeOffer from '../../component/WhatWeOffer'
import Counter from '../../component/counter'
import './style.css'
import TallyPrimeRelease from '../../component/TallyPrimeRelease'
import GoogleTagManager from '../../component/GoogleTagManager'
import IndustriesWeServer from '../../component/IndustriesWeServer'
import OurTechnology from '../../component/OurTechnology'
import TellyprimeSection from '../../component/GloLight'

export default function Home() {
    return (
        <div className='home-main'>
            <GoogleTagManager/>
            <PageLayout>
                <HomeBanner />
                <TellyprimeSection/>
                <Counter />
                <WhatWeOffer />
                <TallyProducts />
                <TallyAppsTabs />
                <TallyPrimeRelease />  
                <IndustriesWeServer/>     
                <OurTechnology/>       
                <OurClient />                 
            </PageLayout>

        </div>
    )
}
