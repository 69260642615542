import React, { useState } from "react";
import IconRightArrow from "../SvgIcons/IconRightArrow";

function CareersForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    contactNo: "",
    applyFor: "",
    file: "",
    validation: {
      firstName: false,
      email: false,
      contactNo: false,
      applyFor: false,
      file: false,
    },
  });

  const handleChange = (e) => {
    const { id, value, type, files } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [id]: type === "file" ? files[0] : value,
      validation: {
        ...prevState.validation,
        [id]: type === "file" ? !files.length : !e.target.checkValidity(),
      },
    }));
  };

  const handleRadioChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      applyFor: e.target.value,
      validation: {
        ...prevState.validation,
        applyFor: false, // Reset validation when a radio button is selected
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const isFormValid =
      form.checkValidity() &&
      formData.applyFor; // Ensure at least one radio button is selected

    setFormData((prevState) => ({
      ...prevState,
      validation: {
        firstName: !form.firstName.checkValidity(),
        email: !form.email.checkValidity(),
        contactNo: !form.contactNo.checkValidity(),
        applyFor: !formData.applyFor, // Checks if applyFor has a value
        file: !formData.file,
      },
    }));

    if (isFormValid) {
      console.log("Form submitted successfully!");
      // Handle form submission here
    }
  };

  return (
    <section className="careers-form mt100">
      <div className="container">
        <div className="row">
          <form
            className="col-12 col-md-12 col-lg-10 col-xl-8 mx-auto formcontroll needs-validation"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="First Name"
                  type="text"
                  className={`form-control ${
                    formData.validation.firstName
                      ? "is-invalid"
                      : formData.firstName && "is-valid"
                  }`}
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Last Name"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control ${
                    formData.validation.email
                      ? "is-invalid"
                      : formData.email && "is-valid"
                  }`}
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Contact No."
                  type="tel"
                  className={`form-control ${
                    formData.validation.contactNo
                      ? "is-invalid"
                      : formData.contactNo && "is-valid"
                  }`}
                  id="contactNo"
                  value={formData.contactNo}
                  onChange={handleChange}
                  maxLength={10}
                  required
                />
              </div>

              <div className="col-12 formfield">
                <input placeholder="Address" type="text" className="form-control" />
              </div>
              <div className="col-12 mt">
                <div className="selectoption text-center">
                  <h4>Apply For</h4>
                </div>
              </div>

              <div className="col-12 mt">
                <div className="ouropening-check d-flex justify-content-start justify-content-sm-center flex-wrap">
                  {[
                    "Support Executive",
                    "Marketing Executive",
                    "Php Developer",
                    "Content Writer",
                    "Android Developers",
                    "Tally Programmers",
                  ].map((option, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="applyFor"
                        id={`inlineRadio${index + 1}`}
                        value={`option${index + 1}`}
                        onChange={handleRadioChange}
                        checked={formData.applyFor === `option${index + 1}`}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`inlineRadio${index + 1}`}
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formData.validation.applyFor && (
                  <div className="invalid-feedback text-center">
                    Please select your option.
                  </div>
                )}
              </div>

              <div className="col-12 mx-auto d-block mt">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5 mx-auto fileupload">
                    <input
                      type="file"
                      className={`form-control ${
                        formData.validation.file
                          ? "is-invalid"
                          : formData.file && "is-valid"
                      }`}
                      id="file"
                      onChange={handleChange}
                    />
                    <label 
                      className={`d-flex justify-content-center align-items-center ${
                        formData.validation.file
                          ? "is-invalid"
                          : formData.file && "is-valid"
                      }`}>
                      + Upload Resume Here
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 mx-auto d-block mt">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5 mx-auto pt50">
                    <button className="btn col-12 btn-purpal" type="submit">
                      <label>Submit <IconRightArrow /></label>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default CareersForm;
